// src/auth/oidcConfig.js
import { UserManager, WebStorageStateStore } from 'oidc-client'; 

const oidcConfig = {
    //authority: "https://localhost:5001", // URL of your IdentityServer
    authority: "https://dev.amnathrig.app",
    client_id: "am_client_314", // The client ID defined in IdentityServer
    //redirect_uri: "https://localhost:44401/callback", // Where the user is redirected after login
    redirect_uri: "https://dev.amnathrig.app/callback",
    response_type: "code", // 'code' is the recommended response type for OIDC
    scope: "openid profile amnathrigAPI roles", // The scopes that determine what information is requested from the user
    //post_logout_redirect_uri: "https://localhost:44401/", // Where the user is redirected after logout
    post_logout_redirect_uri: "https://dev.amnathrig.app/",
    userStore: new WebStorageStateStore({ store: window.localStorage }),
};

const userManager = new UserManager(oidcConfig);

export default userManager;
