import React, { Component } from 'react';
import  userManager from '../auth/oidcConfig'; 


export class FetchData extends Component {
  static displayName = FetchData.name;

  constructor(props) {
    super(props);
    this.state = { forecasts: [], loading: true };
  }

  componentDidMount() {
    this.populateWeatherData();
  }

  static renderForecastsTable(forecasts) {
    return (
      <table className="table table-striped" aria-labelledby="tableLabel">
        <thead>
          <tr>
            <th>Date</th>
            <th>Temp. (C)</th>
            <th>Temp. (F)</th>
            <th>Summary</th>
          </tr>
        </thead>
        <tbody>
          {forecasts.map(forecast =>
            <tr key={forecast.date}>
              <td>{forecast.date}</td>
              <td>{forecast.temperatureC}</td>
              <td>{forecast.temperatureF}</td>
              <td>{forecast.summary}</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  render() {
    let contents = this.state.loading
      ? <p><em>Loading...</em></p>
      : FetchData.renderForecastsTable(this.state.forecasts);

    return (
      <div>
        <h1 id="tableLabel">Weather forecast</h1>
        <p>This component demonstrates fetching data from the server.</p>
        {contents}
      </div>
    );
  }

    async populateWeatherData() {
      
        const user = await userManager.getUser();
        if (user) {
            const response = await fetch('weatherforecast', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${user.access_token}`
                }
            });

            if (response.ok) {
                const data = await response.json();
                this.setState({ forecasts: data, loading: false });
                
            } else {
                console.error('Failed to fetch weather data:', response.status);
            }
        } else {
            console.error('User not authenticated');
        }
    
  }
}
