import React, { useState, useEffect } from 'react';
import axios from 'axios';
import userManager from '../auth/oidcConfig'; // Assuming this is your OIDC client setup

const QRCodeDisplay = ({ content }) => {
    const [qrCodeData, setQrCodeData] = useState('');
    const [token, setToken] = useState('');

    useEffect(() => {
        const fetchQRCode = async () => {
            try {
                // Retrieve the access token
                const user = await userManager.getUser();
                const accessToken = user?.access_token;
                console.log(accessToken);

                if (!accessToken) {
                    console.error('No access token found.');
                    return;
                }

                // Fetch the QR code from the backend API using the token
                const response = await axios.get(`/assets/generate-qrcode`, {
                    params: { content }, // Send the content to generate the QR code
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });

                setQrCodeData(response.data.qrCode); // Base64 data returned by API
            } catch (error) {
                console.error('Error generating QR Code:', error);
            }
        };

        fetchQRCode();
    }, [content]);

    return (
        <div>
            <h2>QR Code</h2>
            {qrCodeData ? (
                <img
                    src={`${qrCodeData}`}
                    alt="Generated QR Code"
                    style={{ maxWidth: '300px', maxHeight: '300px' }}
                />
            ) : (
                <p>Loading QR Code...</p>
            )}
        </div>
    );
};

export default QRCodeDisplay;
