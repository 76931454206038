import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import userManager from '../auth/oidcConfig'; 

const Asset = ({ asset }) => {
    const [newProperty, setNewProperty] = useState({ name: '', value: '' });
    const [token, setToken] = useState('');

    // Fetch the access token when the component mounts
    useEffect(() => {
        userManager.getUser().then(user => {
            if (user && user.access_token) {
                setToken(user.access_token);
            } else {
                console.error('Access token not available');
            }
        }).catch(error => {
            console.error('Error fetching access token:', error);
        });
    }, []);

    const addProperty = () => {
        const hasEmptyProperty = !newProperty.name || !newProperty.value;

        if (hasEmptyProperty) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Property must have a name and value.',
            });
            return;
        }

        axios.post(`/assets/${asset.id}/properties`, newProperty, {
            headers: {
                'Authorization': `Bearer ${token}` // Attach the access token here
            }
        })
            .then(response => {
                asset.properties.push(response.data);
                setNewProperty({ name: '', value: '' });
            })
            .catch(error => {
                console.error('Error adding property:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'There was a problem adding the property. Please try again.'
                });
            });
    };

    return (
        <div>
            {asset.properties.map(property => (
                <div key={property.id}>
                    <strong>{property.name}: </strong>
                    <span>{property.value}</span>
                </div>
            ))}
            <div>
                <input
                    type="text"
                    placeholder="Property Name"
                    value={newProperty.name}
                    onChange={(e) => setNewProperty({ ...newProperty, name: e.target.value })}
                />
                <input
                    type="text"
                    placeholder="Property Value"
                    value={newProperty.value}
                    onChange={(e) => setNewProperty({ ...newProperty, value: e.target.value })}
                />
                <button onClick={addProperty} className="btn btn-primary">Add Property</button>
            </div>
        </div>
    );
};

export default Asset;
