import React from 'react';
import { NavLink } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import userManager from '../auth/oidcConfig'; // Adjust the path as needed

const Logout = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        userManager.signoutRedirect().then(() => {
            navigate('/');  // Redirect to the home page or any page after logout
        }).catch((error) => {
            console.error('Logout failed', error);
        });
    };

    return (
        <NavLink
            href="#"
            onClick={handleLogout}
            className="text-white"
            style={{ cursor: 'pointer' }}
        >
            Logout
        </NavLink>
    );
};

export default Logout;
