import React, { Component } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import Logout from '../auth/Logout';
import userManager from '../auth/oidcConfig';
import { jwtDecode } from 'jwt-decode';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            isAdmin: false,  // Start as not admin by default
            userLoading: true,  // Indicate that we're waiting for user data
        };
    }

    async componentDidMount() {
        this.checkUserRole();
    }

    async checkUserRole() {
        const user = await userManager.getUser();
       

        if (user && user.access_token) {
            const decodedToken = jwtDecode(user.access_token);
            const roles = decodedToken.role || [];
            const isAdmin = Array.isArray(roles)
                ? roles.includes('Admin')
                : roles === 'Admin';

            this.setState({ isAdmin, userLoading: false });
        } else {
            // Retry after a short delay
            setTimeout(() => {
                this.checkUserRole();
            }, 1000); // Adjust delay as needed
        }
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        const { isAdmin, userLoading } = this.state;

        if (userLoading) {
            // Optionally render a loading state, or return null to render nothing while loading
            return null; // Or a spinner/loading text if you prefer
        }

        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" style={{ backgroundColor: '#005500' }} container light>
                    <NavbarBrand tag={Link} to="/" className="text-white">Amnathrig</NavbarBrand>
                    <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                        <ul className="navbar-nav flex-grow">
                            <NavItem>
                                <NavLink tag={Link} className="text-white" to="/">Home</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} className="text-white" to="/view-pending-transfers">View Pending Transfers</NavLink>
                            </NavItem>
                            {isAdmin && (
                                <NavItem>
                                    <NavLink className="text-white" href="/UserManagement">User Management</NavLink>
                                </NavItem>
                            )}
                            <NavItem>
                                <Logout />
                            </NavItem>
                        </ul>
                    </Collapse>
                </Navbar>
            </header>
        );
    }
}
