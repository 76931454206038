import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Asset from './Asset';
import userManager from '../auth/oidcConfig';
import QRCodeDisplay from './QRCodeDisplay'; // Ensure QRCodeDisplay is imported

export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);
        this.state = {
            assets: [],
            showProperties: {},
            selectedAssetForQR: null, // Keep track of the asset selected for QR code generation
            token: ''
        };
    }

    async componentDidMount() {
        try {
            // Get the authenticated user's access token
            const user = await userManager.getUser();
            const token = user?.access_token;
            if (token) {
                // Store the token for future requests
                this.setState({ token });

                // Fetch the protected assets endpoint with the token
                const response = await axios.get('/assets', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                this.setState({ assets: response.data });
            } else {
                console.error('No access token found. User might not be logged in.');
            }
        } catch (error) {
            console.error('Error fetching assets:', error);
        }
    }

    toggleProperties = (assetId) => {
        this.setState(prevState => ({
            showProperties: {
                ...prevState.showProperties,
                [assetId]: !prevState.showProperties[assetId]
            }
        }));
    };

    // Trigger QR code generation for a specific asset
    generateQRCodeForAsset = (assetId) => {
        this.setState({ selectedAssetForQR: assetId });
    };

    render() {
        const { assets, showProperties, selectedAssetForQR, token } = this.state;

        return (
            <div>
                <h1>Your Assets</h1>

                {assets.length > 0 && (
                    <a href="/create-asset" className="btn btn-primary">Create New Asset</a>
                )}

                {assets.length === 0 ? (
                    <p>No assets available to display.</p>
                ) : (
                    assets.map(asset => (
                        <div key={asset.id} style={styles.assetBox}>
                            <h2>
                                <Link to={`/asset-details/${asset.id}`}>
                                    {asset.name}
                                </Link>
                            </h2>
                            <p>{asset.description}</p>
                            <p><strong>Type:</strong> {asset.assetType}</p>

                            {/* Generate QR Code button now always visible */}
                            <button
                                onClick={() => this.generateQRCodeForAsset(asset.id)}
                                className="btn btn-secondary"
                                style={styles.qrButton}
                            >
                                Generate QR Code
                            </button>

                            {showProperties[asset.id] && (
                                <div>
                                    <Asset asset={asset} />
                                </div>
                            )}

                            {/* Display QR Code if selected */}
                            {selectedAssetForQR === asset.id && (
                                <QRCodeDisplay content={`${asset.userId}:${asset.id}`} token={token} />
                            )}

                            <a href="#" onClick={() => this.toggleProperties(asset.id)}>
                                {showProperties[asset.id] ? 'Hide Properties' : 'Show Properties'}
                            </a>
                        </div>
                    ))
                )}

                <a href="/create-asset" className="btn btn-primary">Create New Asset</a>
            </div>
        );
    }
}

// Styles for the asset box and QR button
const styles = {
    assetBox: {
        border: '1px solid #ccc',
        padding: '15px',
        marginBottom: '15px',
        borderRadius: '5px',
        backgroundColor: '#f9f9f9'
    },
    qrButton: {
        marginTop: '10px', // Adds space between asset details and QR button
        marginBottom: '10px' // Adds space between QR button and other elements
    }
};
