// src/auth/Login.js
import React, { useEffect } from 'react';
import userManager from './oidcConfig';

const Login = () => {
    useEffect(() => {
        userManager.signinRedirect();
    }, []);

    return <div>Redirecting to login...</div>;
};

export default Login;
