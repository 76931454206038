import React, { useEffect, useState } from 'react';
import axios from 'axios';
import userManager from '../auth/oidcConfig'; // Import your OIDC client config

const ViewPendingTransfers = () => {
    const [pendingTransfers, setPendingTransfers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [token, setToken] = useState(null);

    useEffect(() => {
        // Retrieve the token on component mount
        userManager.getUser().then(user => {
            if (user && user.access_token) {
                setToken(user.access_token);
                fetchPendingTransfers(user.access_token);
            }
        }).catch(error => {
            console.error('Error fetching token:', error);
        });
    }, []);

    // Function to fetch pending transfers with token authorization
    const fetchPendingTransfers = async (token) => {
        setLoading(true);

        try {
            const response = await axios.get('/assets/pending-transfers', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setPendingTransfers(response.data);
        } catch (error) {
            console.error('Error fetching pending transfers:', error);
        } finally {
            setLoading(false);
        }
    };

    const completeTransfer = async (transferId) => {
        // Ensure the token is available for completing the transfer
        if (!token) {
            console.error('No access token found.');
            return;
        }

        try {
            await axios.post(`/assets/complete-transfer/${transferId}`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            alert('Transfer completed successfully!');
            fetchPendingTransfers(token); // Refresh the list after completion
        } catch (error) {
            console.error('Error completing transfer:', error);
            alert('Failed to complete the transfer.');
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <h2>Pending Transfers</h2>
            {pendingTransfers.length === 0 ? (
                <p>No pending transfers available.</p>
            ) : (
                <table className="table">
                    <thead>
                        <tr>
                            <th>Asset ID</th>
                            <th>Asset Name</th>
                            <th>Transfer From</th>
                            <th>Transfer To</th>
                            <th>Transfer Initiated</th>
                            <th>Transfer Completed</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pendingTransfers.map(transfer => (
                            <tr key={transfer.id}>
                                <td>{transfer.assetId}</td>
                                <td>{transfer.assetName}</td>
                                <td>{transfer.fromUser}</td>
                                <td>{transfer.toUser}</td>
                                <td>{new Date(transfer.transferInitiatedDate).toLocaleString()}</td>
                                <td>{transfer.completed ? new Date(transfer.transferCompletedDate).toLocaleString() : 'Not Completed'}</td>
                                <td>
                                    {!transfer.completed && (
                                        <button className="btn btn-success" onClick={() => completeTransfer(transfer.id)}>
                                            Complete Transfer
                                        </button>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default ViewPendingTransfers;
