import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import userManager from '../auth/oidcConfig'; // Import the OIDC config to get the token

const AssetDetails = () => {
    const { id } = useParams();
    const [asset, setAsset] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [token, setToken] = useState(null); // State to store access token
    const navigate = useNavigate();

    // Fetch token and asset on component mount
    useEffect(() => {
        userManager.getUser().then(user => {
            if (user && user.access_token) {
                setToken(user.access_token); // Set token in state
                axios.get(`/assets/${id}`, {
                    headers: {
                        Authorization: `Bearer ${user.access_token}`
                    }
                })
                    .then(response => setAsset(response.data))
                    .catch(error => console.error('Error fetching asset:', error));
            }
        }).catch(error => {
            console.error('Error getting user token:', error);
        });
    }, [id]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setAsset({ ...asset, [name]: value });
    };

    const handlePropertyChange = (index, event) => {
        const newProperties = [...asset.properties];
        newProperties[index][event.target.name] = event.target.value;
        setAsset({ ...asset, properties: newProperties });
    };

    const handleSave = () => {
        const hasEmptyProperties = asset.properties.some(property => !property.name || !property.value);

        if (hasEmptyProperties) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'All properties must have a name and value.',
            });
            return;
        }

        axios.put(`/assets/${id}`, asset, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                setAsset(response.data);
                setIsEditing(false);
                Swal.fire({
                    icon: 'success',
                    title: 'Asset Updated!',
                    text: 'Your asset has been successfully updated.',
                });
            })
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'There was a problem saving the asset. Please try again.',
                });
            });
    };

    const handleDeleteAsset = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`/assets/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                    .then(() => {
                        Swal.fire(
                            'Deleted!',
                            'The asset has been deleted.',
                            'success'
                        );
                        navigate('/');
                    })
                    .catch(() => {
                        Swal.fire(
                            'Error!',
                            'There was a problem deleting the asset.',
                            'error'
                        );
                    });
            }
        });
    };

    const handleDeleteProperty = (propertyId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`/assets/properties/${propertyId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                    .then(() => {
                        Swal.fire(
                            'Deleted!',
                            'The property has been deleted.',
                            'success'
                        );
                        setAsset({
                            ...asset,
                            properties: asset.properties.filter(prop => prop.id !== propertyId),
                        });
                    })
                    .catch(() => {
                        Swal.fire(
                            'Error!',
                            'There was a problem deleting the property.',
                            'error'
                        );
                    });
            }
        });
    };

    if (!asset) return <div>Loading...</div>;

    return (
        <div>
            <h2>{isEditing ? (
                <input
                    type="text"
                    name="name"
                    value={asset.name}
                    onChange={handleInputChange}
                    className="form-control"
                />
            ) : (
                asset.name
            )}</h2>

            <p>{isEditing ? (
                <textarea
                    name="description"
                    value={asset.description}
                    onChange={handleInputChange}
                    className="form-control"
                />
            ) : (
                asset.description
            )}</p>

            <p><strong>Type:</strong> {isEditing ? (
                <select
                    name="assetType"
                    value={asset.assetType}
                    onChange={handleInputChange}
                    className="form-control"
                >
                    <option value="Vehicle">Vehicle</option>
                    <option value="Appliance">Appliance</option>
                    <option value="Real Estate Property">Real Estate Property</option>
                    <option value="Furniture">Furniture</option>
                    <option value="Electronics">Electronics</option>
                </select>
            ) : (
                asset.assetType
            )}</p>

            {/* Render the properties of the asset */}
            <h3>Properties</h3>
            {asset.properties && asset.properties.length > 0 ? (
                <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                    {asset.properties.map((property, index) => (
                        <li key={index} style={styles.propertyContainer}>
                            {isEditing ? (
                                <>
                                    <input
                                        type="text"
                                        name="name"
                                        value={property.name}
                                        onChange={(event) => handlePropertyChange(index, event)}
                                        className="form-control"
                                        style={styles.inputField}
                                    />
                                    <input
                                        type="text"
                                        name="value"
                                        value={property.value}
                                        onChange={(event) => handlePropertyChange(index, event)}
                                        className="form-control"
                                        style={styles.inputField}
                                    />
                                    <button
                                        onClick={() => handleDeleteProperty(property.id)}
                                        className="btn btn-danger"
                                        style={styles.deleteButton}
                                    >
                                        Delete Property
                                    </button>
                                </>
                            ) : (
                                <span>
                                    <strong>{property.name}:</strong> {property.value}
                                </span>
                            )}
                        </li>
                    ))}
                </ul>
            ) : (
                <p>No properties available for this asset.</p>
            )}

            {isEditing ? (
                <div className="d-flex justify-content-start">
                    <button onClick={handleSave} className="btn btn-primary me-2">Save</button>
                    <button onClick={handleDeleteAsset} className="btn btn-danger">Delete Asset</button>
                </div>
            ) : (
                <button onClick={() => setIsEditing(true)} className="btn btn-secondary" style={styles.editButton}>Edit</button>
            )}
        </div>
    );
};

// Styles for spacing
const styles = {
    propertyContainer: {
        marginBottom: '10px',
        border: '1px solid #005500',
        padding: '10px',
        borderRadius: '5px',
        backgroundColor: '#f9f9f9',
    },
    inputField: {
        marginBottom: '5px',
    },
    saveButton: {
        marginRight: '10px',
    },
    deleteButton: {
        marginTop: '10px',
    },
    editButton: {
        marginTop: '10px',
    }
};

export default AssetDetails;
