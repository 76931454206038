import React, { useState, useEffect } from 'react';
import axios from 'axios';
import userManager from '../auth/oidcConfig';

const CreateAsset = ({ history }) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [assetType, setAssetType] = useState('');
    const [properties, setProperties] = useState([{ name: '', value: '' }]);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [token, setToken] = useState(null);

    const predefinedTypes = [
        'Vehicle',
        'Appliance',
        'Real Estate Property',
        'Furniture',
        'Electronics'
    ];

    useEffect(() => {
        // Retrieve the token on component mount
        userManager.getUser().then(user => {
            if (user && user.access_token) {
                setToken(user.access_token);
            }
        }).catch(error => {
            console.error('Error fetching token:', error);
        });
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newAsset = {
            name,
            description,
            assetType,
            properties
        };

        if (!token) {
            setError('Failed to authenticate. Please try again.');
            return;
        }

        try {
            await axios.post('/assets', newAsset, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setSuccess('Asset created successfully!');
            setName('');
            setDescription('');
            setAssetType('');
            setProperties([{ name: '', value: '' }]);
            setError('');
            // Redirect to home or another page after a short delay
            setTimeout(() => {
                history.push('/');
            }, 2000);
        } catch (err) {
            setError('Failed to create asset. Please try again.');
            setSuccess('');
        }
    };

    const handlePropertyChange = (index, event) => {
        const updatedProperties = [...properties];
        updatedProperties[index][event.target.name] = event.target.value;
        setProperties(updatedProperties);
    };

    const addProperty = () => {
        setProperties([...properties, { name: '', value: '' }]);
    };

    const removeProperty = (index) => {
        const updatedProperties = [...properties];
        updatedProperties.splice(index, 1);
        setProperties(updatedProperties);
    };

    return (
        <div>
            <h2>Create New Asset</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="name">Asset Name</label>
                    <input
                        type="text"
                        className="form-control"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="description">Asset Description</label>
                    <textarea
                        className="form-control"
                        id="description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        required
                    ></textarea>
                </div>

                <div className="form-group">
                    <label htmlFor="assetType">Asset Type</label>
                    <input
                        list="assetTypeOptions"
                        className="form-control"
                        id="assetType"
                        value={assetType}
                        onChange={(e) => setAssetType(e.target.value)}
                        required
                    />
                    <datalist id="assetTypeOptions">
                        {predefinedTypes.map((type, index) => (
                            <option key={index} value={type} />
                        ))}
                    </datalist>
                </div>

                <h3>Properties</h3>
                {properties.map((property, index) => (
                    <div key={index} className="form-group">
                        <label htmlFor={`property-name-${index}`}>Property Name</label>
                        <input
                            type="text"
                            className="form-control"
                            id={`property-name-${index}`}
                            name="name"
                            value={property.name}
                            onChange={(e) => handlePropertyChange(index, e)}
                            required
                        />
                        <label htmlFor={`property-value-${index}`}>Property Value</label>
                        <input
                            type="text"
                            className="form-control"
                            id={`property-value-${index}`}
                            name="value"
                            value={property.value}
                            onChange={(e) => handlePropertyChange(index, e)}
                            required
                        />
                        {properties.length > 1 && (
                            <button type="button" className="btn btn-danger" onClick={() => removeProperty(index)}>Remove Property</button>
                        )}
                    </div>
                ))}
                <button type="button" className="btn btn-secondary" onClick={addProperty}>Add Another Property</button>

                {error && <div className="alert alert-danger">{error}</div>}
                {success && <div className="alert alert-success">{success}</div>}
                <button type="submit" className="btn btn-primary">Create Asset</button>
            </form>
        </div>
    );
};

export default CreateAsset;
