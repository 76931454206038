import { Counter } from "./components/Counter";
import { FetchData } from "./components/FetchData";
import { Home } from "./components/Home";
import Callback from './auth/Callback';
import Login from './auth/Login';
import Logout from './auth/Logout';
import ProtectedRoute from './auth/ProtectedRoute';
import CreateAsset from './components/CreateAsset';
import UserManagement from './components/UserManagement';
import AssetDetails from './components/AssetDetails';
import ViewPendingTransfers from './components/ViewPendingTransfers'; // Import the ViewPendingTransfers component

const AppRoutes = [
    {
        index: true,
        element: <ProtectedRoute element={Home} />
    },
    {
        path: '/create-asset',
        element: <ProtectedRoute element={CreateAsset} />,
    },
    {
        path: '/asset-details/:id',
        element: <ProtectedRoute element={AssetDetails} />
    },
    {
        path: '/user-management',
        element: <UserManagement /> // Only accessible if linked from an admin account
    },
    {
        path: '/view-pending-transfers', // New route for ViewPendingTransfers
        element: <ProtectedRoute element={ViewPendingTransfers} /> // Protected route for the pending transfers
    },
    {
        path: '/callback',
        element: <Callback />,
    },
    {
        path: '/login',
        element: <Login />,
    },
    {
        path: '/logout',
        element: <Logout />,
    },
    {
        path: '/fetch-data',
        element: <ProtectedRoute element={FetchData} />
    },
];

export default AppRoutes;
