// src/auth/Callback.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import userManager from './oidcConfig';

const Callback = () => {
    const navigate = useNavigate();

    useEffect(() => {
        userManager.signinRedirectCallback().then(() => {
            navigate('/');
        }).catch(error => {
            console.error('Login failed', error);
           navigate('/login');
        });
    }, [navigate]);

    return <div>Processing login...</div>;
};

export default Callback;
