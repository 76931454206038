import React, { useEffect } from 'react';

const UserManagement = () => {
    useEffect(() => {
        window.location.href = '/UserManagement';
    }, []);

    return (
        <div>
            <p>Redirecting to User Management...</p>
        </div>
    );
};

export default UserManagement;
