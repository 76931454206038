import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import userManager from './oidcConfig';

const ProtectedRoute = ({ element: Element }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        userManager.getUser().then(user => {
            if (user && !user.expired) {
                setIsAuthenticated(true);
            } else {
                userManager.signinRedirect();
            }
            setLoading(false);
        });
    }, [navigate]);

    if (loading) {
        return <div>Loading...</div>;
    }

    return isAuthenticated ? <Element /> : null;
};

export default ProtectedRoute;

